<template>
  <div class="lg:px-16">
    <section class="md:max-w-[1560px] mx-auto px-4 md:px-16 lg:px-0">
      <div
        class="bg-white py-10 flex w-full items-center flex-col rounded-t-lg lg:py-12"
      >
        <div
          class="bg-white shadow-icone rounded-full w-[70px] h-[70px] flex justify-center items-center mb-4 lg:w-[135px] lg:h-[135px]"
        >
          <inline-svg
            class="mr-1 w-8 lg:w-16"
            :src="require('@/assets/novo/compliance.svg')"
            title="Regulamento"
          />
        </div>
        <div
          class="text-center px-8 text-[#727272] lg:text-lg lg:px-20 xl:px-48"
        >
          <h1 class="text-[#0B5E67] font-bold mb-8">REGULAMENTO</h1>
          <p class="mb-4">
            O Programa RAStrear® (“Programa”) é um programa gratuito oferecido
            pela Interplayers Soluções Integradas S/A (“Interplayers”), inscrita
            no CNPJ 05.347.060/0006-03, em nome da Amgen Biotecnologia do Brasil
            Ltda. (“Amgen”), inscrita no CNPJ 18.774.815/0001-93.
          </p>
          <p class="mb-4">
            O objetivo deste programa é oferecer suporte aos médicos, e
            consequentemente aos seus pacientes, na análise do perfil mutacional
            do câncer colorretal metastático, através da realização do exame
            RAS, cujo objetivo é a identificação molecular do status mutacional
            KRAS e NRAS éxon 2 (códons 12/13), éxon 3 (códon 61); éxon 4 (códons
            117/146), incluindo o KRAS G12C, (doravante “Exame RAS”) e BRAF
            (códon 600).
          </p>
          <p class="mb-4">
            O benefício do programa é obter o resultado da existência ou não da
            mutação e apoiar o médico na tomada de decisão sobre a opção
            terapêutica mais adequada e indicada ao tratamento do paciente.
          </p>
        </div>
      </div>
    </section>
    <section
      class="mx-auto bg-white pt-1 pb-6 lg:px-10 lg:max-w-[1560px] lg:-mt-1"
      id="regulamento"
    >
      <OAccordion class="-mt-3" :items="items">
        <template #como-participar>
          <p>
            Condições necessárias para a participação no Programa RAStrear®:
          </p>
          <ol>
            <li>
              <p>
                Para participar do Programa RAStrear®, o médico deverá se
                cadastrar através do site www.programarastrear.com.br (doravante
                “Site”).
              </p>
            </li>
            <li>
              <p>
                Para participar do Programa RAStrear® é necessário ser ser
                médico(a) oncologista ou patologista e ter número de conselho,
                CPF e endereço de e-mail válidos.
              </p>
            </li>
          </ol>
        </template>
        <template #termos-e-condicoes-de-participacao-no-programa-rastrear>
          <ol>
            <li>
              <p>
                Os testes realizados pelo programa RAStrear devem ser fornecidos
                aos pacientes através dos médicos de maneira inteiramente
                gratuita. A cobrança do paciente ou da operadora de saúde para
                tais testes será considerada infração ao presente regulamento e
                o médico poderá ser impedido de realizar novas solicitações.
              </p>
            </li>
            <li>
              <div>
                O exame KRAS, incluindo KRAS G12C, NRAS e BRAF somente poderá
                ser solicitado caso a amostra contenha tecido de tumor primário
                no cólon e no reto e cujo estadiamento seja IIIb ou IV.
                <ol>
                  <li>
                    <p>
                      Amostras enviadas que não estejam de acordo com o
                      mencionado acima serão devolvidas ao solicitante sem a
                      análise mutacional;
                    </p>
                  </li>
                  <li>
                    <p>
                      Será de responsabilidade do laboratório de análise a
                      avaliação da amostra e documentação e, em caso de não
                      estarem de acordo com o proposto pelo programa, indicar em
                      sistema o motivo da impossibilidade de seguir com o exame.
                    </p>
                  </li>
                  <li>
                    <p>
                      O cancelamento do caso será realizado pela equipe do
                      Programa RAStrear na plataforma do Programa e junto ao
                      solicitante, com base na avaliação disponibilizada pelo
                      laboratório, bem como a amostra devolvida no caso de já
                      ter sido recebida pelo laboratório.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <p>
                O programa não possibilita a solicitação do exame KRAS isolado
                do exame NRAS e BRAF ou vice-versa.
              </p>
            </li>
            <li>
              <p>
                A (o) solicitante do exame deverá ter o voucher disponibilizado
                por um consultor Amgen<sup>®</sup> em seu cadastro no Site. Este
                voucher será liberado a partir do momento que a solicitação for
                feita e for analisado e confirmado que os critérios de
                elegibilidade estão sendo cumpridas para fazer a solicitação.
              </p>
            </li>
            <li>
              <div>
                A análise poderá ser realizada através de amostra de tecido ou
                por amostra líquida, sendo que a biópsia líquida, além dos
                critérios de elegibilidade acima também deverá atender aos
                seguintes critérios:
                <ol>
                  <li>
                    <p>
                      Paciente não poderá estar em tratamento por quimioterapia
                      no momento da solicitação e deverá ter interrompido há
                      pelo menos 15 dias;
                    </p>
                  </li>
                  <li>
                    <p>
                      Quando necessário, a biópsia líquida será oferecida ao
                      solicitante, mas para que haja liberação da biopsia
                      líquida os critérios de elegibilidade abaixo devem ser
                      cumpridos. São eles:
                    </p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div>
                A biópsia líquida poderá ser solicitada pelo médico:
                <ol>
                  <li>
                    <p>
                      no caso de ter apresentado resultado inconclusivo para a
                      análise realizada com a amostra de tecido;
                    </p>
                  </li>
                  <li>
                    <p>
                      caso tenha apresentado resultado selvagem em primeira
                      linha e queiram revisitar o perfil mutacional em terceira
                      linha;
                    </p>
                  </li>
                  <li>
                    <p>
                      rechallenge após falha a 1(uma) ou mais linhas livres de
                      panitumumabe;
                    </p>
                  </li>
                  <li>
                    <p>
                      caso paciente não tenha amostra de tecido disponível e
                      precise partir direto para a biópsia líquida.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div>
                Solicitações de exame serão efetuadas apenas quando solicitadas
                pelo Site.
                <ol>
                  <li>
                    <p>
                      O médico solicitante terá login e senha para efetuar
                      solicitações, acompanhamento das solicitações e acesso aos
                      laudos com resultados.
                    </p>
                  </li>
                  <li>
                    <p>
                      Para consultar o laudo/resultado da análise, o médico
                      solicitante deverá consultar no Site, acessando com seu
                      login e senha. Não será fornecido resultado através do
                      0800 do programa ou e-mail.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div>
                A Amgen® se responsabiliza por toda a logística e análises
                laboratoriais para operacionalizar o processo da realização do
                exame, podendo a logística ser contratada da Amgen ou do
                laboratório responsável pela análise. A empresa de logística
                contratada fará a coleta no local indicado pelo médico
                solicitante no Site, entregará em um dos laboratórios parceiros
                da Amgen® de acordo com área geográfica estabelecida pela Amgen.
                O laboratório realizará a análise e a logística reversa
                (devolução da amostra para o médico solicitante) será acionada.
                <ol>
                  <li>
                    <div>
                      O médico solicitante ou responsável deverá encaminhar,
                      junto à amostra a ser analisada, os documentos
                      obrigatórios para que o exame seja realizado. São eles:
                      <ul>
                        <li>
                          <p>Termo de Consentimento do Paciente</p>
                        </li>
                        <li>
                          <p>Termo de Transporte</p>
                        </li>
                        <li>
                          <p>
                            Laudo Anatomopatológico ou outro documento que
                            comprove o sítio primário sendo colon e/ou reto,
                            sendo o estadio IIIb ou IV
                          </p>
                        </li>
                        <li>
                          <p>Termo de Emergência</p>
                        </li>
                      </ul>
                      <ol>
                        <li>
                          <p>
                            Ao receber a amostra no laboratório, seja ela de
                            tecido ou líquida, na ausência dos documentos
                            “Consentimento do Paciente” ou “Laudo
                            anatomopatológico”, a análise não será iniciada pelo
                            laboratório determinado pela Amgen e responsável e a
                            solicitação ficará “pendente” até que a pendência
                            seja normalizada. Para isso, o médico solicitante
                            deverá entrar em contato com a central de
                            atendimento, pelo telefone ou e-mail, e seguir as
                            orientações.<br /><br />
                            <em
                              >Nota: Após 15 dias da pendência não solucionada
                              sem justificativa, o caso será cancelado e, se
                              aplicável, a amostra devolvida ao solicitante.</em
                            >
                          </p>
                        </li>
                        <li>
                          <p>
                            No caso de amostra de tecido, a amostra deverá ser
                            acondicionada dentro das embalagens fornecidas pelo
                            programa RAStrear®, que será entregue pelo portador
                            logístico no momento da coleta. Os campos
                            preenchíveis na tampa da caixa também deverão ser
                            preenchidos obrigatoriamente, garantindo o correto
                            endereçamento da amostra. Essa medida é para
                            controle e segurança do transporte. Tais medidas são
                            respaldadas e exigidas por lei para transporte de
                            amostra biológicas categorias B.
                          </p>
                        </li>
                        <li>
                          <p>
                            No caso de amostra de biopsia líquida, a coleta será
                            feita a critério de acordo com o laboratório,
                            podendo ser diretamente na clínica/laboratório em
                            que o paciente faz tratamento ou na casa do
                            paciente, sendo que o transporte deverá respeitar as
                            legislações vigentes.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li>
                    <p>
                      A amostra deverá ser acondicionada dentro das embalagens
                      fornecidas pelo programa RAStrear® , que será entregue
                      pelo portador logístico no momento da coleta. Os campos
                      preenchíveis na tampa da caixa também deverão ser
                      preenchidos obrigatoriamente, garantindo o correto
                      endereçamento da amostra. Essa medida é para controle e
                      segurança do transporte. Tais medidas são respaldadas e
                      exigidas por lei para transporte de amostra biológicas
                      categorias B.
                    </p>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <p>
                Os dados pessoais do médico para o cadastro no Programa (nome,
                CPF, número de conselho, telefone e e-mail) e do paciente (nome
                completo, CPF e data de nascimento) deverão ser fornecidos ao
                Programa RAStrear®. Tais informações são providas pelo
                solicitante no momento da solicitação no site e são de total
                responsabilidade do informante.<br /><br />
                <em
                  >As informações solicitadas no cadastramento serão utilizadas
                  exclusivamente para assegurar o correto acesso aos benefícios
                  do programa.</em
                >
              </p>
            </li>
            <li>
              <p>
                Somente será aceito 1 (um) cadastro por Cadastro de Pessoa
                Física do Ministério da Fazenda (“CPF”). Essa informação se
                aplica para CPF do médico solicitante e paciente. Com intuito de
                assegurar a privacidade do paciente, o usuário e senha gerado no
                cadastro é pessoal e intransferível.
              </p>
            </li>
            <li>
              <p>
                O médico participante será excluído do Programa RAStrear® caso
                preste informações inverídicas ou utilize os benefícios
                concedidos para benefício próprio ou como forma lucrativa.
              </p>
            </li>
            <li>
              <p>
                O médico participante garante e confirma que informou aos seus
                pacientes para os quais os exames indicados no item 1 acima
                forem solicitados, que estes são patrocinados pela Amgen, de
                forma inteiramente gratuita ao paciente, e que na hipótese de o
                resultado do exame KRAS, incluindo KRAS G12C, NRAS e BRAF
                demonstrar o resultado mutado, selvagem e ou inconclusivo, o
                médico fará uso da terapia que julgar mais adequada para o seu
                paciente. Vectibix® é um anticorpo monoclonal totalmente humano,
                ANTI-EGFR. Esse medicamento é comercializado pela Amgen® e
                possui indicação para o tratamento do câncer colorretal
                metastático para pacientes com biomarcadores RAS selvagem
                (wild-type).
              </p>
            </li>
            <li>
              <p>
                O Programa RAStrear® vigorará por prazo indeterminado, podendo a
                Amgen® alterá-lo, suspendê-lo ou encerrá-lo a qualquer momento
                mediante comunicado aos participantes por meio do Site.
              </p>
            </li>
          </ol>
        </template>
        <template #privacidade-dos-dados>
          <ol>
            <li>
              <p>
                A Interplayers é a organização que coleta as informações
                pessoais do médico e paciente, conforme descrito neste Aviso de
                Privacidade. Essas informações são necessárias para participar
                do Programa e incluem as informações voluntariamente fornecidas
                ao Programa no momento do cadastramento e durante a solicitação,
                incluindo:
              </p>
              <ol>
                <li>
                  <p>
                    Dados do médico: nome, CPF, endereço, número de conselho
                    válido e informações de contato (telefone e e-mail)
                  </p>
                </li>
                <li>
                  <p>
                    Dados do paciente: nome completo, CPF e data de nascimento.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Processaremos apenas as informações necessárias para inscrever
                você no Programa e processar a análise, objeto do programa.
              </p>
            </li>
            <li>
              <p>
                As informações pessoais acima descritas serão registradas
                diretamente no sistema da Interplayers ao qual apenas empregados
                da Amgen e/ou da Interplayers responsáveis pela gestão do
                Programa terão acesso. Informações agregadas ou anônimas sobre a
                sua participação no programa, bem como os resultados
                encontrados, podem ser fornecidas para efeitos de gestão de
                negócios da Amgen.
              </p>
            </li>
            <li>
              <p>
                Suas informações pessoais serão processadas pela Interplayers
                durante a sua permanência no Programa e somente para:
              </p>
              <ol>
                <li>
                  <p>inscrever você no Programa;</p>
                </li>
                <li>
                  <p>
                    fornecer os serviços para os quais você se registrou, objeto
                    deste regulamento;
                  </p>
                </li>
                <li>
                  <p>para fins de relatórios internos;</p>
                </li>
                <li>
                  <p>
                    e para fins de cumprimento das responsabilidades da Amgen em
                    relação às legislações de farmacovigilância aplicáveis.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                A Interplayers mantém medidas de segurança técnicas e
                organizacionais adequadas para proteger suas informações
                pessoais contra acesso não autorizado ou ilegal, perda
                acidental, alteração ou destruição, de acordo com a legislação
                aplicável
              </p>
            </li>
            <li>
              <p>
                A Amgen, como fabricante do medicamento Vectibix®, é obrigada
                por lei a relatar informações de farmacovigilância às
                autoridades de saúde, por isso algumas das informações do
                paciente podem ser relatadas ao departamento de
                farmacovigilância da Amgen somente para este fim. Esses
                relatórios de farmacovigilância também podem exigir que a Amgen
                contate você para solicitar informações adicionais sobre o
                relato de farmacovigilância, conforme necessário e mediante sua
                autorização. A Amgen deve processar essas informações apenas
                para fins de relatórios de farmacovigilância. Além disso, você
                pode concordar em permitir que a Interplayers forneça seu nome
                completo e meios de contato (telefone e/ou email) ao
                departamento de farmacovigilância da Amgen, a fim de melhorar o
                processo de acompanhamento.
              </p>
            </li>
            <li>
              <p>
                Você está ciente que suas informações que são necessárias para
                processamento dos relatórios de farmacovigilância podem ser
                transferidas para a Amgen Inc. (nos Estados Unidos da América) e
                para processadores confiáveis agindo em nome da Amgen,
                localizados em países fora daquele em que você reside. As
                transferências de informações pessoais entre a Amgen e outras
                empresas do grupo seguem as leis aplicáveis e nossas Regras
                Corporativas Vinculativas (BCRs). Para obter informações sobre
                os BCRs, visite http: /www.amgen.com/bcr/. As transferências
                para fornecedores que processam informações pessoais sob as
                instruções da Amgen são feitas usando Contratos Modelo
                (Cláusulas Contratuais Padrão). Independentemente de onde suas
                informações pessoais são coletadas, a Amgen mantém salvaguardas
                adequadas para garantir um nível adequado de proteção de suas
                informações
              </p>
            </li>
            <li>
              <p>
                A participação no Programa é voluntária e gratuita. No entanto,
                se você decidir não concordar com o processamento de suas
                informações pessoais conforme descrito neste Aviso de
                Privacidade, ou decidir cancelar os serviços disponíveis do
                Programa em uma data posterior, você não poderá processar novas
                solicitações ou consultar dados anteriormente registrados;
              </p>
            </li>
            <li>
              <p>
                Você pode retirar o seu consentimento para participar do
                Programa, a qualquer momento, sem fornecer qualquer motivo,
                notificando a Interplayers através dos dados de contato
                fornecidos abaixo.
              </p>
            </li>
            <li>
              <p>
                Se o Programa for encerrado ou você voluntariamente retirar seu
                consentimento para participar do Programa, suas informações
                pessoais serão destruídas de forma segura, de acordo com a
                Política de Retenção de Dados da Amgen. Em alguns casos, a
                Interplayers e / ou Amgen podem ser obrigados por lei aplicável
                a reter suas informações pessoais além desse período de tempo
              </p>
            </li>
            <li>
              <p>
                Se você desejar solicitar acesso, uma cópia de suas informações
                pessoais, a exclusão de seus dados pessoais ou corrigir ou
                atualizar suas informações pessoais, entre em contato com o
                Programa por meio dos Canais de Comunicação de Privacidade de
                Dados Pessoais fornecidos abaixo.
              </p>
            </li>
            <li>
              <p>
                Caso você não solicite a exclusão dos seus dados, o programa se
                reserva o direito de realizar a exclusão após 15 anos da
                interrupção dos serviços prestados à você, com exceção dos dados
                utilizados para realização de relatos de farmacovigilância, os
                quais permanecerão em sistema pelo período exigido pelas
                legislações vigentes e pelo Cronograma de Retenção de Registros
                da Amgen.
              </p>
            </li>
            <li>
              <p>
                A Amgen não compartilha, sem a expressa autorização do
                participante, as informações da utilização do Programa com
                terceiros para qualquer fim que não seja o mínimo necessário
                para o correto funcionamento do Programa.
              </p>
            </li>
            <li>
              <p>
                Suas informações pessoais podem ser combinadas com as
                informações de terceiros que participam do Programa para gerar
                dados agregados que não contenham informações de identificação
                (“Dados Agregados”). Os Dados Agregados podem ser usados pela
                Amgen e seus provedores de serviços para melhorar e / ou refinar
                o Programa, para projetar e implementar outros programas para
                pacientes e para fins de pesquisa, incluindo a identificação de
                tendências, como utilização de produtos, aderência ou
                resultados.
              </p>
              <ol>
                <li>
                  <p>
                    Suas informações pessoais não serão usadas para nenhum
                    propósito além dos propósitos descritos acima neste Aviso de
                    Privacidade. Se você deseja registrar uma reclamação sobre o
                    uso de suas informações pessoais pelo Programa, entre em
                    contato com Interplayers, Amgen e / ou Autoridade Nacional
                    de Proteção de Dados (Autoridade Nacional de Proteção de
                    Dados ou “ANPD”). Canais de Comunicação de Privacidade de
                    Dados Pessoais: Diretamente no departamento de privacidade
                    de dados da Amgen através do e-mail privacyoffice@amgen.com
                  </p>
                </li>
                <li>
                  <p>
                    Através do Programa pelo telefone 0800 940 0113 ou e-mail
                    <a
                      class="a-link italic font-semibold"
                      href="mailto:contato@programarastrear.com.br"
                    >
                      contato@programarastrear.com.br.</a
                    >
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </template>
        <template #disposicoes-gerais>
          <ol>
            <li>
              <p>
                A Amgen reserva-se o direito de alterar este Regulamento a
                qualquer momento, sem aviso prévio. A versão atualizada deste
                Regulamento encontra-se disponível no site:<a
                  href="https://www.programarastrear.com.br"
                  target="_blank"
                  class="a-link italic font-semibold"
                >
                  www.programarastrear.com.br</a
                >
              </p>
            </li>
            <li>
              <p>
                A existência do Programa RAStrear® deve auxiliar exclusivamente
                os médicos que atendam aos requisitos expressos nesse
                Regulamento e não deve possuir influência sobre o registro,
                aquisição ou potencial aquisição, indicação ou prescrição de
                qualquer medicamento da Amgen, sendo respeitada a independência
                e autonomia dos profissionais médicos e dos serviços de saúde
                pela determinação dos melhores procedimentos para com seus
                pacientes, em razão do quadro clínico de cada paciente.
              </p>
            </li>
          </ol>
        </template>
      </OAccordion>
      <div class="bg-white rounded-b-md pt-4 pb-8">
        <small class="block text-center text-[#C4C4C4] font-bold mt-2">
          Regulamento atualizado em Outubro de 2023.
        </small>
      </div>
    </section>
    <section class="mb-14 -mt-4 md:-mt-7 lg:-mt-8">
      <BigButton
        class="flex justify-center w-full px-6 md:max-w-[432px] md:mx-auto"
      />
    </section>
  </div>
</template>

<script>
import BigButton from "@/components/molecules/BigButton.vue";
import OAccordion from "@/components/organisms/Accordion.vue";
import { computed } from "vue";

export default {
  name: "RegulamentoEDocumentos",
  components: {
    BigButton,
    OAccordion,
  },
  setup() {
    const items = computed(() => [
      { name: "como-participar", title: "I. COMO PARTICIPAR" },
      {
        name: "termos-e-condicoes-de-participacao-no-programa-rastrear",
        title: "II. TERMOS E CONDIÇÕES DE PARTICIPAÇÃO NO PROGRAMA RASTREAR",
      },
      { name: "privacidade-dos-dados", title: "III. PRIVACIDADE DOS DADOS" },

      { name: "disposicoes-gerais", title: "IV. DISPOSIÇÕES GERAIS" },
    ]);

    return { items };
  },
};
</script>

<style lang="postcss">
.o-accordion {
  &__item__content {
    @apply bg-white !text-[#727272] rounded-b-md -mt-2 text-xs text-left md:text-sm
    md:px-12 lg:px-14 xl:px-16 lg:text-base;

    &*:nth-of-type(1) {
      @apply mt-6;
    }

    ol {
      @apply list-decimal px-4;

      ol {
        list-style-type: lower-alpha;
        @apply my-4;

        ol {
          list-style-type: lower-roman;
        }
      }
    }

    ul {
      @apply list-disc px-5 my-4;
    }

    p,
    ol:not(:nth-last-child(1)) {
      @apply mb-4;
    }

    li:not(:nth-last-child(1)) {
      @apply mb-4;
    }

    & > li {
      @apply font-bold;
    }

    li {
      &::marker {
        font-weight: bold !important;
      }

      p {
        @apply font-normal;
      }
    }
  }
}
</style>
